// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-project-template-1-js": () => import("./../../../src/components/projectTemplate1.js" /* webpackChunkName: "component---src-components-project-template-1-js" */),
  "component---src-components-project-template-2-js": () => import("./../../../src/components/projectTemplate2.js" /* webpackChunkName: "component---src-components-project-template-2-js" */),
  "component---src-components-project-template-3-js": () => import("./../../../src/components/projectTemplate3.js" /* webpackChunkName: "component---src-components-project-template-3-js" */),
  "component---src-components-shop-item-js": () => import("./../../../src/components/shopItem.js" /* webpackChunkName: "component---src-components-shop-item-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */)
}

